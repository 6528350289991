import {BannerData, DwhData, SlotData, SlotInsertComplete} from '@/interfaces/SlotInsertComplete';
import getFeatures from '@/utils/features';
import {getSlotBlockName} from '@/utils/helpers';

export const getSlotBlockData = (banner: BannerData) => {
	const {nodeId} = banner;
	const slotBlockName = getSlotBlockName(banner);
	const slotBlockNode: HTMLElement = document.querySelector(`#${nodeId}`);

	return {
		slotBlockNode,
		slotBlockName,
	};
};

export const getData = (data: SlotInsertComplete) => {
	const features = getFeatures();

	const {container: slotNode, params: slotParams, result, time = 1} = data;
	const {slot} = result;
	const {expId, slotCounter, siteId, slotId, slotRequiresHtml} = slotParams;

	let {slotName} = slotParams;

	slotName = String(slotName).toLowerCase();

	const dwh: DwhData = {
		siteId,
		slotId,
		slotName,
	};

	if (expId) {
		dwh.expId = expId;
	}

	const slotData: SlotData = {
		slotNode,
		slotParams,
		slotName,
	};

	return {
		dwh,
		features,
		slot,
		slotCounter,
		slotData,
		slotId,
		slotName,
		slotRequiresHtml,
		time,
	};
};
