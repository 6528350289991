import {DwhCallback, DwhData, SlotData} from '@/interfaces/SlotInsertComplete';
import {checkJbJsonComment} from '@/logger/checkRbJson';
import {onSlotClickHandler} from '@/logger/click';
import {getSlotBlockData} from '@/logger/data';
import {onFrameMouseEnterHandler, onFrameMouseLeaveHandler} from '@/logger/mouseActions';
import {logSlotBlockState} from '@/logger/visibility';
import getFeatures, {getFeatureData} from '@/utils/features';
import getSlotHandlerKeeper from '@/utils/handlerKeeper';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const logSlotState = (
	slotData: SlotData,
	params: {dwh: DwhData; getState; setState; dwhCallback?: DwhCallback},
) => {
	const {dwh, getState, setState, dwhCallback} = params;

	const features = getFeatures();

	const directReadyTimeout = getFeatureData('adman-insert-context-timeout', 1800);

	const {slotNode, slotName, slotHTMLData} = slotData;

	const handlerKeeper = getSlotHandlerKeeper(slotName);

	handlerKeeper.clearAll();

	const onReady = () => {
		for (const banner of slotHTMLData) {
			const {fallback = []} = banner;
			const {slotBlockNode, slotBlockName} = getSlotBlockData(banner);

			if (slotBlockNode && (!fallback.length || slotBlockNode.offsetHeight)) {
				logSlotBlockState({...slotData, slotBlockNode, slotBlockName}, banner, dwh, dwhCallback);
			} else {
				const logFallback = fallback.some((fallbackBanner) => {
					fallbackBanner.bannerId = fallbackBanner.bannerId || banner.bannerId;

					const {slotBlockNode, slotBlockName} = getSlotBlockData(fallbackBanner);
					if (slotBlockNode) {
						logSlotBlockState(
							{...slotData, slotBlockNode, slotBlockName},
							fallbackBanner,
							dwh,
							dwhCallback,
						);
					}

					return slotBlockNode;
				});

				if (!logFallback) {
					logSlotBlockState({...slotData, slotBlockNode, slotBlockName}, banner, dwh, dwhCallback);
				}
			}
		}

		const frames = slotNode.querySelectorAll('iframe');

		for (let i = frames.length; i--; ) {
			const frame = frames[i];

			handlerKeeper.addEventHandler(frame, 'mouseenter', (event) =>
				onFrameMouseEnterHandler(event, {slotData, dwh, getState, setState}),
			);
			handlerKeeper.addEventHandler(frame, 'mouseleave', () => onFrameMouseLeaveHandler(setState));
		}

		setTimeout(() => checkJbJsonComment(slotData, features), 100);
	};

	if (slotNode) {
		handlerKeeper.addTimer(setTimeout(onReady, directReadyTimeout));

		handlerKeeper.addEventHandler(slotNode, 'click', (event) =>
			onSlotClickHandler(event, {slotData, dwh}),
		);
	}
};
