import {BannerData, DwhCallback, DwhData, SlotData, XrayData} from '@/interfaces/SlotInsertComplete';
import getSlotHandlerKeeper from '@/utils/handlerKeeper';
import inView from '@/utils/inView';
import {sendXray, sendXrayWithChain, updateChain} from '@/utils/xray';

export const logSlotBlockState = (
	slotData: SlotData,
	banner: BannerData,
	dwh: DwhData,
	dwhCallback?: DwhCallback,
) => {
	const chain = [];
	const {slotBlockNode, slotName} = slotData;

	const {bannerId, source: bannerSource, format: bannerFormat, count: bannerCount} = banner;

	const handlerKeeper = getSlotHandlerKeeper(slotName);

	dwh = {
		...dwh,
		bannerId,
		bannerSource,
		bannerFormat,
		bannerCount,
	};

	const xray: XrayData = {
		chain,
		dwh,
	};

	updateChain(chain, ['draw'], slotData);

	if (slotBlockNode) {
		if (slotBlockNode.offsetParent) {
			updateChain(chain, ['visible'], slotData);
			if (dwhCallback) {
				dwhCallback(dwh);
			}

			if (inView.is(slotBlockNode)) {
				updateChain(chain, ['visible_in_view', 'visible_in_view_start'], slotData);
			} else {
				handlerKeeper.addInViewWatcher(
					inView.watch(slotBlockNode, () => {
						sendXrayWithChain(xray, ['visible_in_view', 'visible_in_view_after'], slotData);
					}),
				);

				updateChain(chain, ['visible_no_in_view'], slotData);
			}
		} else {
			updateChain(chain, ['hidden', 'hidden_visible'], slotData);
		}
	} else {
		updateChain(chain, ['hidden', 'hidden_no_in_dom'], slotData);
	}

	sendXray(xray);
};
