import {DwhData, SlotClickData, SlotData} from '@/interfaces/SlotInsertComplete';
import hasBlocker from '@/utils/adblockerDetector';
import getFeatures, {FEATURES, filterURL, getFeatureData} from '@/utils/features';
import {findSlotBlockByNode, findSlotBlockLink, submitForm} from '@/utils/helpers';
import {sendXrayWithChain} from '@/utils/xray';

import {getSlotBlockData} from './data';

export const logSlotClick = ({target, slotData, dwh}: SlotClickData) => {
	const {slotNode, slotHTMLData} = slotData;

	const banner = findSlotBlockByNode(slotHTMLData, target, slotNode);

	if (banner) {
		const {bannerId, source: bannerSource, format: bannerFormat, count: bannerCount} = banner;

		const {slotBlockName} = getSlotBlockData(banner);

		dwh = {
			...dwh,
			bannerId,
			bannerSource,
			bannerFormat,
			bannerCount,
		};

		sendXrayWithChain({dwh}, ['click'], {...slotData, slotBlockName});
	}
};

export const onSlotClickHandler = (evt: MouseEvent, params: {slotData: SlotData; dwh: DwhData}) => {
	const {slotData, dwh} = params;
	const features = getFeatures();
	const target = evt.target as HTMLElement;
	const {slotNode} = slotData;

	// Вот этот кусок не имеет прямого отношения к adman-logger
	if (
		features.has(FEATURES.ADMAN_HANDLE_LINK) &&
		(!features.has(FEATURES.ADMAN_HANDLE_ONLY_SPEC) || hasBlocker())
	) {
		const link = findSlotBlockLink(target, slotNode);
		if (link) {
			const action = getFeatureData(FEATURES.ADMAN_HANDLE_LINK, '');

			// Ничего не делаем, если URL какой-то левый
			if (
				filterURL(FEATURES.ADMAN_HANDLE_LINK, action, {mail: true, imgsmail: true, local: true}) ===
				null
			) {
				return;
			}

			submitForm(action, {
				mimic_link: link.href,
			});

			evt.preventDefault();
		}
	}

	logSlotClick({target, slotData, dwh});
};
