import {DwhCallback, SlotInsertComplete, XrayData} from '@/interfaces/SlotInsertComplete';
import {getData} from '@/logger/data';
import {logSlotState} from '@/logger/logSlotState';
import {FEATURES} from '@/utils/features';
import {getSlotData, isCommercialSlot, isSlotHasHtml, prepareStrForRlog} from '@/utils/helpers';
import {updateChain} from '@/utils/xray';

function onRenderSlotWithHtml(
	data: SlotInsertComplete,
	xray: XrayData,
	state,
	dwhCallback?: DwhCallback,
): void {
	const {status} = data.result;
	const {features, slot, slotData, slotId, slotName, time} = getData(data);
	const {chain, dwh} = xray;
	const {html = '', ...fields} = slot;
	const {getState, setState} = state;
	const {slotParams} = slotData;
	const {expId} = slotParams;

	if (isCommercialSlot(slot)) {
		const slotHTMLData = getSlotData(slot);
		if (slotHTMLData) {
			logSlotState({...slotData, slotHTMLData}, {dwh, setState, getState, dwhCallback});
		} else {
			updateChain(chain, ['empty_slot_data'], slotData);
			if (dwhCallback) {
				dwhCallback(dwh);
			}
			if (features.has(FEATURES.ADMAN_LOG_EMPTY_SLOT_DATA)) {
				xray.rlog = {
					name: 'empty_slot_data',
					message: {
						...fields,
						html: prepareStrForRlog(html),
						slotId,
						slotName,
					},
				};
			}
		}

		updateChain(chain, ['complete', status], slotData, String(time));
	} else {
		if (dwhCallback) {
			dwhCallback(dwh);
		}
		if (features.has(FEATURES.ADMAN_LOG_COMM_SLOT)) {
			xray.rlog = {
				name: 'comm-slot',
				message: {
					...fields,
					html: prepareStrForRlog(html),
					slotId,
					slotName,
				},
			};
		}
	}

	updateChain(chain, ['has_answer'], slotData);
}

function onRenderSlotWithoutHtml(data: SlotInsertComplete, xray: XrayData): void {
	const {status} = data.result;
	const {slotData, slotRequiresHtml, time} = getData(data);
	const {chain} = xray;

	if (slotRequiresHtml) {
		updateChain(chain, ['complete', status], slotData, String(time));
	}

	updateChain(chain, ['empty_answer'], slotData);
}

export const onRender = (
	data: SlotInsertComplete,
	xray: XrayData,
	state,
	dwhCallback?: DwhCallback,
): void => {
	const {slot, slotCounter} = getData(data);

	if (slotCounter) {
		return;
	}

	if (isSlotHasHtml(slot)) {
		onRenderSlotWithHtml(data, xray, state, dwhCallback);
	} else {
		onRenderSlotWithoutHtml(data, xray);
	}
};
